
html,
body {
  margin: 0;
}

#App {
  
}

#page-wrap {
  /* text-align: center; */

  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 24px;
  height: 18px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #FFFFFF;
  
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  padding-right: 50px;
  
}

li {
  float: right;
}

li a {
  display: block;
  color: #EEEEEE;
  text-align: center;
  margin: 32px 12px;
  text-decoration: none;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;/* identical to box height, or 143% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;



  /* Black / 200 */
  opacity: 0.7;
}



li a:hover {
  color: #FFFFFF !important;
}

li a:active{
  color: #FFFFFF;
  opacity: 1;
}

li .phoneNumber{
  width: 136px;
  height: 28px;
 
  margin: 30px 12px;
  margin-left:20px;
  
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;/* identical to box height, or 175% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  
  color: #FFFFFF;

}

.isSelected{
  color: #FFFFFF;
  opacity: 1;
}




.mainDiv {
  
  height: 100vh;
  background: url(/static/media/mainDiv.fd0ce1d9.svg);
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  background-color: #3D5DDE;
}

.mainRow {
  
  /* left: 150px; */
  padding-top: 150px;
}

.leftDiv{
  padding-left:50px;
}

.login{

  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
 
  /* #4F88FD */
  border: 2px solid #4F88FD;
  box-sizing: border-box;
  background: #3D5DDE;
  border-radius: 4px;
  width: 95px;
  height: 36px;

  margin: 24px 16px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;/* or 171% */
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;

  color: #FFFFFF;

  /* White */

}



.login:hover {
  background-color: #516ee1;
  color: white;
}

@media screen and (max-width: 767px) {
  .mainRow {padding-top:120px;}
  .mainDiv {height:auto;}
  .buttonDiv {text-align: center}
  .leftDiv {padding-left:0px;}
  .prodexCaption {
    padding-left:0px !important;
    margin-left: 0px !important;
  }
}


.prodexCaption{

  margin: 25px 16px;
  float:left;
  color: #FFFFFF;

  padding-left:35px;
  

  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
  
  color: #FFFFFF;


}


.mainHeader {
  /* Все еще ведете учет на бумажках? */

  position: relative;
  /* width: 459px; */
  /* height: 119px; */
  /* left: 150px; */

  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  line-height: 59px;
  text-transform: uppercase;

  color: #FFFFFF;
  
/* White */
}

.buttonDiv{
 text-align: left;
 padding-bottom: 50px;
}



.belowMainHeader {
  /* Prodex - система контроля производства для трикотажных фабрик которая даст вам моментальную аналитику по всему производственному процессу, чтобы вы могли сконцентрироваться на развитии бизнеса */

  position: relative;
  /* width: 510px; */
  /* height: 127px; */
  /* left: 150px; */

  text-align: left;
  padding-top: 40px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.1px;

  color: #FFFFFF;
/* White */
}

/* Rectangle 2 */
.tryButton {
  position: relative;
  width: 320px;
  height: 64px;
  /* left: 150px; */
  margin-top: 40px;

  background: #FFDE67;
  border-radius: 4px;



  text-align: center;
  padding: 20px 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;/* identical to box height */
  /* display: flex; */
  align-items: center;
  letter-spacing: 0.15px;

  color: #212121;

  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;


  border: 2px solid #FFDE67;
 

  /* Black / 900 */
  /* border-radius: 100px; */
  
}

.tryButton:hover {
  background-color: transparent;
  color: white;

  border: 2px solid white /* Green */
  
  
}

.mouseDiv {
 
  position: absolute;
  bottom: 4px;
  height: 60px;
  width: 25px;
  background-image:url(/static/media/mouse.efa5ccc5.svg);
  background-repeat:no-repeat;
  background-size:contain;
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto;

}





.secondDiv {
  
  background: url(/static/media/Background.9b5398e6.svg);
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}


.secondRow {
  
  
}

.believeIt {
  /* ПРОВЕРЬТЕ ЭТО! */

  position: relative;
 
  
  padding-top: 80px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 24px;/* identical to box height, or 240% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;

  color: #757575;

  /* Black / 600 */
  
  
}

.dontBeLikeOthers{
  /* Your brilliant featu */

  position: relative;
  text-align: left;
  
  padding-top: 10px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 42px;
  line-height: 49px;/* or 117% */

  color: #0F2B9A;
}

.thirdRow{
  /* Your brilliant featu */

  position: relative;
  
  padding-top: 80px;

}


.fourthRow{
  /* Your brilliant featu */

  position: relative;
  
  padding-top: 80px;

}

.fifthRow{
  /* Your brilliant featu */

  position: relative;
  
  padding-top: 80px;

}

.sixthRow{
  /* Your brilliant featu */

  position: relative;
  
  padding-top: 192px;

}


.yellowDiv{
  
  
  

  background: #FFDE67;
  border-radius: 4px;
  padding: 40px 23px;
  height: 100%;
}

.leftYellowCaption{
 

  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;/* or 133% */
  display: flex;
  align-items: center;

  color: #212121;
}
.leftYellowDescription{
 
  padding-top: 32px;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;/* or 133% */
  letter-spacing: 0.15px;

  color: #212121;
}


.instantReport {
  /* Violet feature */

  /* height: 32px;
  left: 10.49%;
  right: 54.1%; */
 text-align: left;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;/* identical to box height, or 133% */

  color: #424242;

  /* Black / 800 */

}

.instantReportDescription{
  /* Make your everyday l */
  text-align: left;
  position: relative;
  padding-top: 32px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;/* or 156% */
  letter-spacing: 0.25px;

  color: #757575;

  /* Black / 600 */
}



.cuttingRoom {
  /* macbook-mock-dribbble */
  
    position: relative;
    width: 400.91px;
  height: 300px;
    /* left: 681px; */
  
   
  }
  
  .cuttingRoomImage{
    width: 100%;
    height: 100%;
    
  
    background: url(/static/media/cuttingRoom.b3935d3d.png);
    background-repeat:no-repeat;
    background-size:contain;
  
  }

.employeeControl{
  /* Orange feature */

  position: relative;
  height: 32px;
  

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;/* identical to box height, or 133% */
  text-align: right;

  color: #424242;
}

.employeeControlDescription{

  position: relative;
  padding-top: 32px;
 

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;/* or 156% */
  text-align: right;
  letter-spacing: 0.25px;

  color: #757575;

  /* Black / 600 */
}



  .stock{
    position: relative;
  
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;/* or 133% */

    color: #424242;

    /* Black / 800 */

  }
  .stockDescription{
    position: relative;
    text-align: left;
    padding-top: 32px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;/* or 156% */
    letter-spacing: 0.25px;

    color: #757575;

    /* Black / 600 */

  }

  .secondTryButton{
    /* Попробовать бесплатно */

    position: relative;
    width: 320px;
    height: 64px;
   
    background: #4F88FD;
    border-radius: 4px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;/* identical to box height */
    /* display: flex; */
    align-items: center;
    letter-spacing: 0.15px;

    color: #FFFFFF;

    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
  
    border: 2px solid white /* Green */

    /* White */
    /* border-radius: 100px; */

  }

  .secondTryButton:hover {
    background-color: #689afd;
    color: white;
    
    
  }

  .tryAgainDiv {
    padding-top: 80px;
    text-align: center

  }



.aboutUs{
  /* Your brilliant featu */

  position: relative;
  
  padding-top: 177px;

}

.whyCaption{
 
  padding-top: 10px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 42px;
  line-height: 49px;/* identical to box height, or 117% */

  color: #3A56C6;
}

.aboutUsCaption{


  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 24px;/* identical to box height, or 240% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;

  color: #757575;
}

.aboutUsRow{
  padding-top: 120px;

}

.aboutUsRow2{
  padding-top: 20px;

}

.aboutUsContent{


  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 36px;/* or 200% */
  letter-spacing: 0.0676924px;

  color: #757575;
}

.authorName{


  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 28px;/* identical to box height, or 87% */
  letter-spacing: 0.120342px;

  color: #757575;

}

.authorRow{
  padding-top: 51px;
}

.authorTitle{
  /* CEO of Company */
  padding-top:21px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 11px;/* identical to box height, or 110% */
  letter-spacing: 1px;
  text-transform: uppercase;

  color: #3A56C6;
}

.roundedImg{
  border-radius: 50%;
}


.purchaseDiv {
  
  margin-top: 221px;
  
  /* background: url(../img/mainDiv.svg);
  background-repeat: no-repeat; 
  background-size: cover;  */
  background: #3D5DDE;
  padding-bottom : 137px;
}

.saveYourTime{
  /* Do You Have Any Ques */

  
  padding-top: 133px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 11px;/* identical to box height, or 110% */
  letter-spacing: 1px;
  text-transform: uppercase;

  color: #FFFFFF;

  /* White */
}

.tryForFree {
  /* Get in touch */

  padding-top:11px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 42px;
  line-height: 49px;/* identical to box height, or 117% */
  letter-spacing: 0.157949px;

  color: #FFDE67;
}

.whatWeOffer{
  
  padding-top:45px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 36px;/* or 200% */

  color: #FFFFFF;

  /* White */
}

.yourName{

  margin-top:155px;
  width:100%;
  border-radius: 4px;
  border: 2px solid #3A56C6; /* Green */

  height:54px;
  background-color: #3A56C6;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKNSURBVHgB7ZqBddMwEIZ/8xigTIA6AWGCBhZoNoDHIukEtJ0g3QC6ADUTNExgMUG7wXGHZGpIKKS66OxI33v/c95LYutOZ/l8pwaZIKIjPixYJ6wZy7GO4tf3LM9as1rWddM09zgE2HDHumLd0W6s5L+YKjLjrHNK5zxGz3SIs96RHh1NJRp4oDPSNX7ohBnGDOnP/DYnOCjSQAkK9+ktwuq+TzzrtdZT4hn0WGL/xiNeYwklVCIghmWHvBxzFHgkohUBZ8iPShQkR0C89++QH1kDjlPXAo0IWMCGPrVOQsMBc9hxgkQ0HPAKdiQnRhoOcLDDIRGNRZBgCC+CSTZoJkKTRMMBloWL5GtrOMDDDo9ENBzwDXaskYiGA1rY0SIRrVRYXoQsylYvzFPhOIBr5OdKoyZQX4ehQBzIJfJxqWG8UHxJTJUpFkXVoZLL4j1UcmOkh0Jr7ILSkXNMqzU2hB6ao7sgjdRVjllXewr8C3poj88RqkgO29vjX1mfD6Y9XqlUKpVKZbTsNRGi8PLS7wl8GY8ufu3++LlHSIj6pOg7QmLkOSlKLn7+De16gGR6YrA0LR1064QtQgVajutRFETY6DmC0afI3yMcps0tnsjODhgY/Q42leBteITIkFLZmseIxJbhJnzS96wbGj+3FMbakEbfNp6so+nR0X84onnE8DkfVrDt/2vgWR9YLd8WG57YKItT3OTMH28wfeMFx/rC+rgtGn6LAAoVmE9Q2HoyUjzrLavrF8lfDojGH8qsP4bHwAk/HUD5mhpjwSM2V/o14ALlGC841lLWg4ZsGptj4Y1EwBnKZSEOsNzoaM2p3AKm+/ysqfsEUTjVASic6gAUTvEOeI5xYJaL/ABsZ2DhlnCb9QAAAABJRU5ErkJggg==); 
  background-size: 16px 16px; /* Resize the background image to cover the entire container */
  background-position: 19px 19px; 
  background-repeat: no-repeat;
  padding-left: 50px;

  color: #FFFFFF;



  
}

.yourName::-webkit-input-placeholder {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  /* line-height: 16px; */
  /* identical to box height, or 114% */
  letter-spacing: 0.0538462px;

  color: #FFFFFF;
}

.yourName::-moz-placeholder {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  /* line-height: 16px; */
  /* identical to box height, or 114% */
  letter-spacing: 0.0538462px;

  color: #FFFFFF;
}

.yourName::-ms-input-placeholder {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  /* line-height: 16px; */
  /* identical to box height, or 114% */
  letter-spacing: 0.0538462px;

  color: #FFFFFF;
}

.yourName::placeholder {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  /* line-height: 16px; */
  /* identical to box height, or 114% */
  letter-spacing: 0.0538462px;

  color: #FFFFFF;
}

.yourPhone{

  margin-top:32px;
  width:100%;
  border-radius: 4px;
  height:54px;
  border: 2px solid #3A56C6; /* Green */
  background-color: #3A56C6;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAM+SURBVHgB7ZyBcRMxEEW/0wCmg0sFZGiASwWEChxogFQA7iB04FABUIGhgpAKzh04VPDZjWTi3ARbd769O0l+M5pknMzZ+t5daSWtAIFkIW0pbc3D+KbPQkp4cQ4VZpt1UiL5b71rlkiEifYG3XM/mUxeIgGsBIIINEECnODITswEEsOcIgFUoBVsSEYgK5IR6B42nCEBji62BxXoD2x4hQRQgX7DhmRczCoGFUgASwuaylyoRORYBmklejc7kZRJXWwFG94icjYTxV+w4Sz2lGMjkFkcknaBiLEWSCkQMQ8CSRz6Cbvh/hYRs52sWljRSsT/gYjZFqjrjqhFvkMq0O1udIXubCSRajxBOlXxcJISp75g9hWHoW51LnHHclQcDvnmS7YnTbeqQ7cFfRTH89yadNO0I223qiOWMGX4Xn0eblWHYfv1eYqjMCxYL5AzDAvWJXIl0IqSOebSiqMV7SHQiiomclChFYFWdI1cYXj6USJXpPNfAgSqsnU1hs+us3a1K4ZxhVxhWMDOOgUJPXReMbUT96E0cLVbZhy0Q0Y1Jc9UhG5UqwJFyjPrZ7MimGxFarLIbyqSt+pLaXNp4zkwwfCgrSxpELjlmXrMpqq9l1q3rozO+My0Q17713bRScGJfmvy43Pgv6/gFvlX6ADfeR0M9gmvmwu6sXDnP8Odf+1/B8jYaT0Ow0c2sqN5krecLosB6Z+3kHbKrguh5IE3bEbrtIQ24mxTSTtF17C5SAs2jEu0F2eDzTyOzdxNqRi4nsT+xFHWsIJuuG3KNXdYE/sV5wFYwnYiVdJmGIE4CqyR97ho2bEl/UjHgcRReim89R3VgFegOTdwR4kHWRnorTKZLrbMpX1ERPReui1CXcLNugtEwCC17d7lNHktMXIGqZvXPEzaufz6HrbVRgcz+O0I3prm0mYYIaO5PmKsbjeaqynG6najvYCEblVQpwQlBmT0N7TQLYjpssggMSqaK2x8jCqlfYKbQ/Xy2aO848dblVqU1sQW/mWTvkR/CZIX6w2cWOXWn7ro2/ckbonahm7hTa/FUNEKPC1Nb9JfXSx7nZxAdXySXOBRrBd4vFekqP1UNrsfH3Tq8ReB0rHHuhHgfwAAAABJRU5ErkJggg==); 
  background-size: 16px 16px; /* Resize the background image to cover the entire container */
  background-position: 19px 19px; 
  background-repeat: no-repeat;
  padding-left: 50px;
  color: #FFFFFF;
  
}

.yourPhone::-webkit-input-placeholder {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  /* line-height: 16px; */
  /* identical to box height, or 114% */
  letter-spacing: 0.0538462px;

  color: #FFFFFF;
}

.yourPhone::-moz-placeholder {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  /* line-height: 16px; */
  /* identical to box height, or 114% */
  letter-spacing: 0.0538462px;

  color: #FFFFFF;
}

.yourPhone::-ms-input-placeholder {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  /* line-height: 16px; */
  /* identical to box height, or 114% */
  letter-spacing: 0.0538462px;

  color: #FFFFFF;
}

.yourPhone::placeholder {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  /* line-height: 16px; */
  /* identical to box height, or 114% */
  letter-spacing: 0.0538462px;

  color: #FFFFFF;
}

.finalBuyButton{

    position: relative;
    width: 225px;
    height: 54px;
    /* left: 150px; */
    margin-top: 32px;
  
    background: #FFDE67;
    border-radius: 4px;
  
  
  
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;/* identical to box height, or 114% */
    text-align: center;
    color: #212121;
  
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
   
  
    border: 2px solid #FFDE67;
    /* Black / 900 */
    /* border-radius: 100px; */
    
  
}

.finalBuyButton:hover {
  background-color: transparent;
  color: white;

  border: 2px solid white /* Green */
  
  
}

.footerProdexCaption{
 

  font-family: Roboto;
  font-size: 18px;
  line-height: 22px;/* or 122% */
  letter-spacing: 0.0676924px;

  color: #FAFAFA;
}

.footerCopyright{
  
  padding-top: 8px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;/* identical to box height, or 150% */
  letter-spacing: 0.0451282px;

  color: #FFFFFF;
}

.footerDiv{
padding-top: 21px;
padding-bottom: 21px;

background: #3A56C6;

}

.emailCaption{
  /* e-mail */


  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 11px;/* identical to box height, or 110% */
  letter-spacing: 1px;
  text-transform: uppercase;

  color: #FFDE67;

}

.emailContent{


  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 36px;/* identical to box height, or 225% */
  letter-spacing: 0.0676924px;

  color: #FFFFFF;
}
