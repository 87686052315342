

.purchaseDiv {
  
  margin-top: 221px;
  
  /* background: url(../img/mainDiv.svg);
  background-repeat: no-repeat; 
  background-size: cover;  */
  background: #3D5DDE;
  padding-bottom : 137px;
}

.saveYourTime{
  /* Do You Have Any Ques */

  
  padding-top: 133px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 11px;/* identical to box height, or 110% */
  letter-spacing: 1px;
  text-transform: uppercase;

  color: #FFFFFF;

  /* White */
}

.tryForFree {
  /* Get in touch */

  padding-top:11px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 42px;
  line-height: 49px;/* identical to box height, or 117% */
  letter-spacing: 0.157949px;

  color: #FFDE67;
}

.whatWeOffer{
  
  padding-top:45px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 36px;/* or 200% */

  color: #FFFFFF;

  /* White */
}

.yourName{

  margin-top:155px;
  width:100%;
  border-radius: 4px;
  border: 2px solid #3A56C6; /* Green */

  height:54px;
  background-color: #3A56C6;
  background-image: url(../img/form_name.png); 
  background-size: 16px 16px; /* Resize the background image to cover the entire container */
  background-position: 19px 19px; 
  background-repeat: no-repeat;
  padding-left: 50px;

  color: #FFFFFF;



  
}

.yourName::placeholder {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  /* line-height: 16px; */
  /* identical to box height, or 114% */
  letter-spacing: 0.0538462px;

  color: #FFFFFF;
}

.yourPhone{

  margin-top:32px;
  width:100%;
  border-radius: 4px;
  height:54px;
  border: 2px solid #3A56C6; /* Green */
  background-color: #3A56C6;
  background-image: url(../img/form_phone.png); 
  background-size: 16px 16px; /* Resize the background image to cover the entire container */
  background-position: 19px 19px; 
  background-repeat: no-repeat;
  padding-left: 50px;
  color: #FFFFFF;
  
}

.yourPhone::placeholder {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  /* line-height: 16px; */
  /* identical to box height, or 114% */
  letter-spacing: 0.0538462px;

  color: #FFFFFF;
}

.finalBuyButton{

    position: relative;
    width: 225px;
    height: 54px;
    /* left: 150px; */
    margin-top: 32px;
  
    background: #FFDE67;
    border-radius: 4px;
  
  
  
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;/* identical to box height, or 114% */
    text-align: center;
    color: #212121;
  
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
   
  
    border: 2px solid #FFDE67;
    /* Black / 900 */
    /* border-radius: 100px; */
    
  
}

.finalBuyButton:hover {
  background-color: transparent;
  color: white;

  border: 2px solid white /* Green */
  
  
}

.footerProdexCaption{
 

  font-family: Roboto;
  font-size: 18px;
  line-height: 22px;/* or 122% */
  letter-spacing: 0.0676924px;

  color: #FAFAFA;
}

.footerCopyright{
  
  padding-top: 8px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;/* identical to box height, or 150% */
  letter-spacing: 0.0451282px;

  color: #FFFFFF;
}

.footerDiv{
padding-top: 21px;
padding-bottom: 21px;

background: #3A56C6;

}

.emailCaption{
  /* e-mail */


  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 11px;/* identical to box height, or 110% */
  letter-spacing: 1px;
  text-transform: uppercase;

  color: #FFDE67;

}

.emailContent{


  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 36px;/* identical to box height, or 225% */
  letter-spacing: 0.0676924px;

  color: #FFFFFF;
}