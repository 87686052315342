
.secondDiv {
  
  background: url(../img/Background.svg);
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}


.secondRow {
  
  
}

.believeIt {
  /* ПРОВЕРЬТЕ ЭТО! */

  position: relative;
 
  
  padding-top: 80px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 24px;/* identical to box height, or 240% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;

  color: #757575;

  /* Black / 600 */
  
  
}

.dontBeLikeOthers{
  /* Your brilliant featu */

  position: relative;
  text-align: left;
  
  padding-top: 10px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 42px;
  line-height: 49px;/* or 117% */

  color: #0F2B9A;
}

.thirdRow{
  /* Your brilliant featu */

  position: relative;
  
  padding-top: 80px;

}


.fourthRow{
  /* Your brilliant featu */

  position: relative;
  
  padding-top: 80px;

}

.fifthRow{
  /* Your brilliant featu */

  position: relative;
  
  padding-top: 80px;

}

.sixthRow{
  /* Your brilliant featu */

  position: relative;
  
  padding-top: 192px;

}


.yellowDiv{
  
  
  

  background: #FFDE67;
  border-radius: 4px;
  padding: 40px 23px;
  height: 100%;
}

.leftYellowCaption{
 

  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;/* or 133% */
  display: flex;
  align-items: center;

  color: #212121;
}
.leftYellowDescription{
 
  padding-top: 32px;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;/* or 133% */
  letter-spacing: 0.15px;

  color: #212121;
}


.instantReport {
  /* Violet feature */

  /* height: 32px;
  left: 10.49%;
  right: 54.1%; */
 text-align: left;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;/* identical to box height, or 133% */

  color: #424242;

  /* Black / 800 */

}

.instantReportDescription{
  /* Make your everyday l */
  text-align: left;
  position: relative;
  padding-top: 32px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;/* or 156% */
  letter-spacing: 0.25px;

  color: #757575;

  /* Black / 600 */
}



.cuttingRoom {
  /* macbook-mock-dribbble */
  
    position: relative;
    width: 400.91px;
  height: 300px;
    /* left: 681px; */
  
   
  }
  
  .cuttingRoomImage{
    width: 100%;
    height: 100%;
    
  
    background: url(../img/cuttingRoom.png);
    background-repeat:no-repeat;
    background-size:contain;
  
  }

.employeeControl{
  /* Orange feature */

  position: relative;
  height: 32px;
  

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;/* identical to box height, or 133% */
  text-align: right;

  color: #424242;
}

.employeeControlDescription{

  position: relative;
  padding-top: 32px;
 

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;/* or 156% */
  text-align: right;
  letter-spacing: 0.25px;

  color: #757575;

  /* Black / 600 */
}



  .stock{
    position: relative;
  
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;/* or 133% */

    color: #424242;

    /* Black / 800 */

  }
  .stockDescription{
    position: relative;
    text-align: left;
    padding-top: 32px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;/* or 156% */
    letter-spacing: 0.25px;

    color: #757575;

    /* Black / 600 */

  }

  .secondTryButton{
    /* Попробовать бесплатно */

    position: relative;
    width: 320px;
    height: 64px;
   
    background: #4F88FD;
    border-radius: 4px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;/* identical to box height */
    /* display: flex; */
    align-items: center;
    letter-spacing: 0.15px;

    color: #FFFFFF;

    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
  
    border: 2px solid white /* Green */

    /* White */
    /* border-radius: 100px; */

  }

  .secondTryButton:hover {
    background-color: #689afd;
    color: white;
    
    
  }

  .tryAgainDiv {
    padding-top: 80px;
    text-align: center

  }


