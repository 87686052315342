

.mainDiv {
  
  height: 100vh;
  background: url(../img/mainDiv.svg);
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  background-color: #3D5DDE;
}

.mainRow {
  
  /* left: 150px; */
  padding-top: 150px;
}

.leftDiv{
  padding-left:50px;
}

.login{

  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
 
  /* #4F88FD */
  border: 2px solid #4F88FD;
  box-sizing: border-box;
  background: #3D5DDE;
  border-radius: 4px;
  width: 95px;
  height: 36px;

  margin: 24px 16px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;/* or 171% */
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;

  color: #FFFFFF;

  /* White */

}



.login:hover {
  background-color: #516ee1;
  color: white;
}

@media screen and (max-width: 767px) {
  .mainRow {padding-top:120px;}
  .mainDiv {height:auto;}
  .buttonDiv {text-align: center}
  .leftDiv {padding-left:0px;}
  .prodexCaption {
    padding-left:0px !important;
    margin-left: 0px !important;
  }
}


.prodexCaption{

  margin: 25px 16px;
  float:left;
  color: #FFFFFF;

  padding-left:35px;
  

  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
  
  color: #FFFFFF;


}


.mainHeader {
  /* Все еще ведете учет на бумажках? */

  position: relative;
  /* width: 459px; */
  /* height: 119px; */
  /* left: 150px; */

  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  line-height: 59px;
  text-transform: uppercase;

  color: #FFFFFF;
  
/* White */
}

.buttonDiv{
 text-align: left;
 padding-bottom: 50px;
}



.belowMainHeader {
  /* Prodex - система контроля производства для трикотажных фабрик которая даст вам моментальную аналитику по всему производственному процессу, чтобы вы могли сконцентрироваться на развитии бизнеса */

  position: relative;
  /* width: 510px; */
  /* height: 127px; */
  /* left: 150px; */

  text-align: left;
  padding-top: 40px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.1px;

  color: #FFFFFF;
/* White */
}

/* Rectangle 2 */
.tryButton {
  position: relative;
  width: 320px;
  height: 64px;
  /* left: 150px; */
  margin-top: 40px;

  background: #FFDE67;
  border-radius: 4px;



  text-align: center;
  padding: 20px 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;/* identical to box height */
  /* display: flex; */
  align-items: center;
  letter-spacing: 0.15px;

  color: #212121;

  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;


  border: 2px solid #FFDE67;
 

  /* Black / 900 */
  /* border-radius: 100px; */
  
}

.tryButton:hover {
  background-color: transparent;
  color: white;

  border: 2px solid white /* Green */
  
  
}

.mouseDiv {
 
  position: absolute;
  bottom: 4px;
  height: 60px;
  width: 25px;
  background-image:url(../img/mouse.svg);
  background-repeat:no-repeat;
  background-size:contain;
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto;

}



