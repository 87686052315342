.aboutUs{
  /* Your brilliant featu */

  position: relative;
  
  padding-top: 177px;

}

.whyCaption{
 
  padding-top: 10px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 42px;
  line-height: 49px;/* identical to box height, or 117% */

  color: #3A56C6;
}

.aboutUsCaption{


  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 24px;/* identical to box height, or 240% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;

  color: #757575;
}

.aboutUsRow{
  padding-top: 120px;

}

.aboutUsRow2{
  padding-top: 20px;

}

.aboutUsContent{


  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 36px;/* or 200% */
  letter-spacing: 0.0676924px;

  color: #757575;
}

.authorName{


  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 28px;/* identical to box height, or 87% */
  letter-spacing: 0.120342px;

  color: #757575;

}

.authorRow{
  padding-top: 51px;
}

.authorTitle{
  /* CEO of Company */
  padding-top:21px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 11px;/* identical to box height, or 110% */
  letter-spacing: 1px;
  text-transform: uppercase;

  color: #3A56C6;
}

.roundedImg{
  border-radius: 50%;
}